/**
 *  floor_map.js
 *
 *  Created by Satoshi Ishizu
 *  Copyright tateoki Inc. All rights reserved.
 */
"use strict";

import * as util from "./util";
import * as constants from "./constants";

/**
 * カテゴリ Floor Map オブジェクト定義です
 */
class FloorMapModel {
  /**
   * コンストラクタです
   * @param {NodeListOf<DivElement>} categoriesElement
   */
  constructor(categoriesElement) {
    this.treeData = [];

    categoriesElement.forEach((item) => {
      const spineLink = item.querySelector(".category_spine_link");
      this.treeData.push({
        id: item.id,
        parent: item.parentNode.classList.contains("categories") ? "#" : item.parentNode.id,
        text: spineLink.dataset.displayName,
        data: spineLink.getAttribute("href"),
      });
    });
  }

  /**
   * カテゴリ（多階層）を流し込みます
   * @param {Function} injectedSetInternalDispatchEvent - 内部リンクのイベントディスパッチ関数
   */
  SetCategories(injectedSetInternalDispatchEvent) {
    // jsTree 初期化
    const floormapStructure = document.getElementById("floormap-structure");
    $(floormapStructure)
      .jstree({
        core: {
          data: this.treeData,
          themes: {
            dots: false, // ノード間の点を非表示
            icons: false,
            responsive: false,
          },
          dblclick_toggle: false,
        },
        plugins: ["wholerow"], // 行全体をクリック可能へ
      })
      .on("ready.jstree", function () {
        // フロアマップ初期表示
        const categoryId = document.querySelector(".categories").getAttribute("load-category_id");
        if (!categoryId) return;
        openToNode(categoryId);
      });

    // ワンクリックでノードを開閉
    floormapStructure.addEventListener("click", function (e) {
      const target = e.target.closest(".jstree-anchor");
      if (target) {
        const instance = $.jstree.reference(target);
        const node = instance.get_node(target);

        // フロアマップ部の開閉
        instance.toggle_node(node);

        // カテゴリの状態取得
        const spinLink = document.querySelector(`.category_spine_link[href="${node.data}"]`);
        const isOpen = util.isOpenCategory(spinLink.parentElement);
        const handler = injectedSetInternalDispatchEvent(spinLink, false);

        // フロアマップとカテゴリの連動処理
        if (instance.is_closed(node)) {
          // フロアマップが閉じたらカテゴリも閉じる
          if (isOpen) spinLink?.click();
        } else {
          // フロアマップが開いたらカテゴリも開く
          handler(new Event("click"));
        }
      }
    });
  }

  /**
   * フロアマップ UI（ポップアップ）のハンドルを設定します
   */
  SetSidebarToggleHandlers() {
    // 開くボタンのクリックイベント
    document.getElementById("open-sidebar").addEventListener("click", function () {
      document.getElementById("sidebar").classList.add("active");
      document.getElementById("open-search").classList.add("hidden");
      document.querySelector("#move-btn .right-arrow").classList.add("hidden");
      this.classList.add("hidden");
    });

    // 閉じるボタンのクリックイベント
    document.getElementById("close-sidebar").addEventListener("click", function () {
      document.getElementById("sidebar").classList.remove("active");
      document.getElementById("open-search").classList.remove("hidden");
      document.querySelector("#move-btn .right-arrow").classList.remove("hidden");
      document.getElementById("open-sidebar").classList.remove("hidden");
    });
  }
}

/**
 * フロアマップインスタンスを取得します
 * @returns
 */
function getJstreeInstance() {
  const floormapStructure = document.getElementById("floormap-structure");
  if (!floormapStructure) return;
  return $.jstree.reference(floormapStructure);
}

/**
 * ノードクリック状態を示すクラスを付与・解除します
 * @param {string} nodeId - 対象ノードの ID
 */
function addClickedClass(nodeId) {
  const jstreeInstance = getJstreeInstance();
  const nodeElement = jstreeInstance?.get_node(nodeId, true);

  if (nodeElement && nodeElement.length > 0) {
    // nodeElement 配下の aria-selected 属性を持つすべての要素を取得
    const selectedElements = nodeElement[0].querySelectorAll("[aria-selected='true']");

    // それぞれの要素の aria-selected を false に設定
    selectedElements.forEach((el) => el.setAttribute("aria-selected", "false"));

    // jstree-wholerow-clicked と jstree-clicked の両方を持つ要素を取得
    const elements = document.querySelectorAll(".jstree-wholerow-clicked, .jstree-clicked");

    // それぞれの要素からクラスを削除
    elements.forEach((el) => {
      el.classList.remove("jstree-wholerow-clicked", "jstree-clicked");
    });

    // クリック対象のノードを選択状態へ
    const selectedElement = nodeElement[0].querySelector("[aria-selected='false']");
    selectedElement.setAttribute("aria-selected", "true");
    selectedElement.classList.add("jstree-clicked");

    // jstree-wholerow-clicked クラスを付与
    const wholerowElement = nodeElement[0].querySelector(".jstree-wholerow");
    wholerowElement.classList.add("jstree-wholerow-clicked");
  }
}

/**
 * 指定したノードまですべて展開します
 * @param {string} nodeId - 対象ノードの ID
 */
export function openToNode(nodeId) {
  const jstreeInstance = getJstreeInstance();

  // 親階層を順次開く
  jstreeInstance
    .get_node(nodeId)
    .parents.reverse()
    .forEach((parentId) => {
      if (parentId !== "#") jstreeInstance.open_node(parentId);
    });

  // 最後に指定のノードを開く
  jstreeInstance.open_node(nodeId);

  // 中央位置へのアニメーション移動（表示後でないと実行できない）
  scrollToCenter(nodeId);
}

/**
 * 指定したノードとその配下のすべての子ノードを閉じます
 * @param {string} nodeId - 対象ノードの ID
 */
export function closeAllChildren(nodeId) {
  const jstreeInstance = getJstreeInstance();
  const node = jstreeInstance?.get_node(nodeId);

  if (!node) return;

  // 配下のすべてのノードを再帰的に閉じる
  function closeRecursively(currentNode) {
    jstreeInstance.close_node(currentNode); // ノードを閉じる

    // 子ノードがあれば再帰的に処理
    currentNode.children.forEach((childId) => {
      const childNode = jstreeInstance.get_node(childId);
      if (childNode) closeRecursively(childNode);
    });
  }

  addClickedClass(nodeId);
  closeRecursively(node); // 指定ノードから再帰的に閉じる
}

/**
 * 指定された要素が中央位置となるようにスクロールアニメーションを実行します
 * @param {string} nodeId - 対象ノードの ID
 */
export function scrollToCenter(nodeId) {
  const jstreeInstance = getJstreeInstance();
  const nodeElement = jstreeInstance?.get_node(nodeId, true);

  if (!nodeElement && nodeElement.length > 0) return;

  // 移動量の算出
  const container = document.getElementById("sidebar").querySelector(".list-unstyled");
  const containerHeight = container.clientHeight;
  const elementHeight = nodeElement[0].clientHeight;
  const elementOffsetTop = nodeElement[0].offsetTop;

  // 中央位置を計算
  const scrollPosition = elementOffsetTop - containerHeight / 2 + elementHeight * 2;

  // 選択状態の設定
  addClickedClass(nodeId);

  // スクロールアニメーション実行
  container.scrollTo({
    top: scrollPosition,
    left: 0,
    behavior: "smooth",
  });
}

/**
 * 初期化処理です
 * @param {DivElement} categories - カテゴリ要素の DOM
 * @param {Function} injectedSetInternalDispatchEvent - 内部リンクのイベントディスパッチ関数
 */
export function init(categories, injectedSetInternalDispatchEvent) {
  // フロアマップ初期化
  const categoriesFloorMap = new FloorMapModel(categories);

  // カテゴリ流し込み
  categoriesFloorMap.SetCategories(injectedSetInternalDispatchEvent);

  // フロアマップ UI のハンドル設定
  categoriesFloorMap.SetSidebarToggleHandlers();
}

"use strict";

import { defaultNameResolver } from "webpack/lib/NamedChunksPlugin";
import ConcatenatedModule from "webpack/lib/optimize/ConcatenatedModule";
import * as floorMap from "./floor_map";
import * as util from "./util";
import * as constants from "./constants";
import createConsoleLogger from "webpack/lib/logging/createConsoleLogger";

// 本棚エレメント
const container = document.querySelector(".categories");

// サイト設定
const siteSettings = document.getElementById("site-settings");

// アニメーション中のクリック制御
let isClickable = true;

// DOMツリー読み込み完了後の初期化処理
document.addEventListener("DOMContentLoaded", init);

/**
 * 初期化処理です
 */
function init() {
  // 本棚エレメントとサイト設定の存在チェック
  if (!container || !siteSettings) return;

  // サーバから受取る共通定義の存在チェック
  if (!gon || !gon.alignment) location.href = "/500.html";

  console.log("load");

  // 遅延読込み設定
  setLazySizesConfig();

  // リサイズとカテゴリ読み込み
  handleResize();
  loadCategory();

  // アニメーション完了時のイベント登録
  handleLoaderAnimationCompletion();

  // クリックイベント登録
  setClickEventModalButtonLinks();
  setClickEventCategory();
  setClickEventMoveLeftRightBtn();
  setClickEventOpenSearchBtn();

  // リサイズとホイールイベント登録
  window.addEventListener("resize", handleResize);
  container.addEventListener("wheel", handleVerticalScroll);

  // 画面全体で右クリックからのコンテキストメニューを非表示
  document.addEventListener("contextmenu", (e) => e.preventDefault());

  // フロアマップ初期化（内部リンクイベント注入）
  floorMap.init(container.querySelectorAll(".category"), setInternalDispatchEvent);
}

/**
 * 遅延読込み（横スクロール向け）設定です
 */
function setLazySizesConfig() {
  // lazysizes の設定
  window.lazySizesConfig = {
    expand: 400, // 先読みサイズの設定（10冊程度を目途に先読み）
    hFac: 1.0, // 水平方向の拡張を有効化（横スクロールの場合 1.0 - 1.5 推奨）
    init: true,
  };
}

/**
 * リサイズイベント時に全てのカテゴリと本の要素の調整、左右ボタンの表示/非表示を制御します
 */
function handleResize() {
  document.querySelectorAll(".category").forEach((el) => resizeCategory(el));
  document.querySelectorAll(".book").forEach((book) => resizeBook(document.querySelector(".category"), book));

  const moveBtnId = document.getElementById("move-btn");
  const alignment = util.isLandscape() ? siteSettings.dataset.sideControlLandscape : siteSettings.dataset.sideControlPortrait;
  moveBtnId.classList.toggle("hidden", alignment == gon.alignment.NONE);
}

/**
 * カテゴリをロードして表示を更新（展開）します
 */
function loadCategory() {
  const categoryId = container?.getAttribute("load-category_id");

  // トップページへのアクセスかカテゴリへのアクセスかのチェック
  if (!categoryId) return;

  // カテゴリ表示
  const categoryElement = document.getElementById(categoryId);
  if (categoryElement) viewCategory(categoryElement);
}

/**
 * 親カテゴリも考慮して指定カテゴリを表示します
 * @param {HTMLElement} categoryElement - 表示するカテゴリ要素
 */
function viewCategory(categoryElement) {
  // 親要素のカテゴリ探索
  const parentCategoryElements = findAncestorCategories(categoryElement);

  // 対象カテゴリ追加（履歴残し対象）
  parentCategoryElements.push(categoryElement);

  // 対象カテゴリを表示するために親カテゴリから順次開く
  parentCategoryElements.forEach((parentCategory, index, array) => {
    const isOpen = util.isOpenCategory(parentCategory);
    if (isOpen) return; // 開いている要素には何もしない
    const eleLink = parentCategory.querySelector(".category_spine_link");
    const newUrl = eleLink.getAttribute("href");
    const isLast = index === array.length - 1;
    // 親カテゴリはアニメーションや履歴残しの対象外
    getUrlData(parentCategory, newUrl, isLast ? undefined : gon.alignment.NONE, isLast ? undefined : false);
  });
}

/**
 * 指定された階層のルールに基づき、指定の要素をアニメーションで移動させます
 * @param {string} targetLayer - 移動する階層を示す定数
 * @param {HTMLElement} targetElement - 移動対象の要素
 * @param {string} alignment - 寄せ位置の指定
 * @param {number } initialStartTime - タイムアウト用途の開始時間（注：引数指定しないこと）
 */
function moveAnimation(targetLayer, targetElement, alignment = gon.alignment.OPEN_LEFT_ALIGNMENT, initialStartTime = null) {
  // 指定された寄せ位置が無効な場合は処理しない
  if (alignment == gon.alignment.NONE) return;

  // 初回の呼び出しで開始時間を設定
  if (!initialStartTime) initialStartTime = performance.now();

  // 対象要素が非表示の場合、次フレームで再試行
  if (!targetElement || !targetElement.offsetParent) {
    const currentTime = performance.now();
    if (currentTime - initialStartTime > constants.ANIMATION_DURATION.TIMEOUT) {
      console.log("Timeout: Target element is still not visible.");
      return; // タイムアウトした場合は処理を中断
    }
    requestAnimationFrame(() => moveAnimation(targetLayer, targetElement, alignment, initialStartTime));
    return;
  }

  // アニメーション遅延スタート
  setTimeout(() => {
    _startAnimation(targetLayer, targetElement, alignment);
  }, constants.ANIMATION_DURATION.CATEGORY_OPEN);

  // アニメーションロジック
  function _startAnimation(targetLayer, targetElement, alignment) {
    // 要素の中央にスクロールするためのスクロール位置を計算
    function getCenterPosition(element) {
      const containerRect = container.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();
      return elementRect.left + container.scrollLeft - (containerRect.left + containerRect.width / 2) + elementRect.width / 2;
    }

    // 要素の左端にスクロールするためのスクロール位置を計算
    function getLeftPosition(element) {
      const containerRect = container.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();
      return elementRect.left + container.scrollLeft - containerRect.left;
    }

    let targetScroll = null;

    // 対象の階層に応じてスクロール位置を設定
    switch (targetLayer) {
      case constants.MULTI_LAYER.CATEGORY:
        if (alignment == gon.alignment.OPEN_CENTERING) {
          targetScroll = getCenterPosition(targetElement);
        } else {
          targetScroll = getLeftPosition(targetElement);
        }
        break;
      case constants.MULTI_LAYER.BOOK:
        targetScroll = getCenterPosition(targetElement);
        break;
      default:
        return;
    }

    let startTime = null;

    // イージング関数（時間 t に応じてイージングされた値を計算して返却）
    function easeInOutCubic(t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t * t + b;
      t -= 2;
      return (c / 2) * (t * t * t + 2) + b;
    }

    // アニメーションのフレームごとに実行される関数
    function step(timestamp) {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      // イージング関数を使ってスクロール位置を計算
      const scrollX = easeInOutCubic(progress, container.scrollLeft, targetScroll - container.scrollLeft, constants.ANIMATION_DURATION.POSITION);
      container.scrollLeft = scrollX;
      // アニメーションの持続時間が経過していなければ次のフレームを要求
      if (progress < constants.ANIMATION_DURATION.POSITION) {
        requestAnimationFrame(step);
      }
    }

    // アニメーションを開始
    requestAnimationFrame(step);
  }
}

/**
 * カテゴリのサイズを調整します
 * @param {HTMLElement} container - コンテナ要素
 */
function resizeCategory(container) {
  const categoryElementHeight = document.querySelector(".category").clientHeight;
  const img = container.querySelector(".spine-image");
  const marginTopPx = container.getAttribute("data-margin-top");

  // カテゴリの高さを設定
  setSpineHeight(img, categoryElementHeight, marginTopPx);

  // 余白設定
  container.style.paddingTop = `${marginTopPx}px`;

  // 各詳細要素の処理
  container.querySelectorAll(".category-detail").forEach((element) => {
    element.style.height = img.style.height;
    setDetailWidth(element, element, categoryElementHeight);
  });
}

/**
 * 本のサイズを調整します
 * @param {HTMLElement} container
 * @param {HTMLElement} element
 */
function resizeBook(container, element) {
  // ブラウザの表示領域と詳細表の高さの基準値から比率を計算
  const rate = container.clientHeight / constants.DEFAULT_IMAGE_HEIGHT;
  const marginTopPx = Math.floor(rate * element.getAttribute("data-margin-top"));

  // 本の背表紙の高さを計算
  const bookSpine = element.querySelector(".spine-image");
  setSpineHeight(bookSpine, container.clientHeight, marginTopPx);

  // 本の詳細の高さを設定
  const bookDetail = element.querySelector(".book-detail");
  bookDetail.style.height = bookSpine.style.height;

  // 詳細の横幅を設定
  setDetailWidth(bookDetail, element, container.clientHeight);
}

/**
 * 詳細の幅を設定します
 * @param {HTMLElement} detailElement - 幅を設定する詳細の要素
 * @param {HTMLElement} getAttributeElement - data 属性の data-detail-width が設定されている要素
 * @param {number} clientHeight - class='category' の要素
 */
function setDetailWidth(detailElement, getAttributeElement, clientHeight) {
  const viewportWidth = document.body.clientWidth * 0.7;
  const rate = clientHeight / constants.DEFAULT_IMAGE_HEIGHT;
  const detailWidth = Math.floor(rate * getAttributeElement.getAttribute("data-detail-width"));

  detailElement.style.width = `${Math.min(viewportWidth, detailWidth)}px`;
}

/**
 * 背表紙画像の高さを設定します
 * @param {HTMLElement} spineElement - 表紙画像の要素
 * @param {number} clientHeight - ブラウザの表示領域
 * @param {number} marginTop - 表紙上部のマージンサイズ
 */
function setSpineHeight(spineElement, clientHeight, marginTop) {
  const spineHeight = clientHeight - marginTop;
  spineElement.style.height = `${spineHeight}px`;
}

/**
 * 内部リンクのイベントディスパッチです
 * @param {*} internalLinkElement
 * @param {boolean} registerEvent - true ならクリックイベントを登録する
 * @returns {Function|null} クリックイベントハンドラ（registerEvent が false の場合のみ）
 */
function setInternalDispatchEvent(internalLinkElement, registerEvent = true) {
  const internalLink = internalLinkElement.getAttribute("href");

  // 内部リンクに対応するカテゴリの背表紙リンク要素を取得
  const categorySpineLinkElement = document.querySelector(`.category_spine_link[href="${internalLink}"]`);

  // デバイスの向きに応じた内部リンク制御設定を取得
  const internalLinkControlSetting = Number(util.isLandscape() ? siteSettings.dataset.internalLinkControlLandscape : siteSettings.dataset.internalLinkControlPortrait);

  // パンくずリストの更新とアニメーション処理を実行
  const updateHistoryAndAnimate = (linkElement, categoryElement, controlSetting) => {
    const spineImageElement = categoryElement.querySelector(".spine-image");
    handleResize(); // リサイズ処理を実行
    moveAnimation(constants.MULTI_LAYER.CATEGORY, spineImageElement, controlSetting); // アニメーションを実行
    updateHistoryElement(linkElement); // パンくずリストを更新
  };

  // クリックイベントハンドラ
  const handleClick = (event) => {
    event.preventDefault(); // デフォルトのリンク動作をキャンセル
    event.stopPropagation(); // イベントのバブリングを停止

    // リンク先カテゴリ要素の取得
    const categoryElement = categorySpineLinkElement?.parentElement;
    if (!categoryElement) return;

    // フロアマップ連動
    floorMap.openToNode(categoryElement.id);

    // 親要素のカテゴリ探索
    const parentCategoryElements = findAncestorCategories(categoryElement);

    // 親要素のカテゴリ全表示
    parentCategoryElements.forEach((parentCategoryElement) => {
      if (util.isOpenCategory(parentCategoryElement)) return; // 開いていたら次の親要素へ
      const viewUrl = parentCategoryElement.querySelector(".category_spine_link").getAttribute("href");
      getUrlData(parentCategoryElement, viewUrl, gon.alignment.NONE, false);
    });

    // 移動先のカテゴリが表示されているか判定
    const isOpen = util.isOpenCategory(categoryElement);

    // パンくずリスト経由のアクセスか判定
    const isFromBrowsingHistory = internalLinkElement.classList.contains("history");

    if (isOpen) {
      updateHistoryAndAnimate(isFromBrowsingHistory ? internalLinkElement : categorySpineLinkElement, categoryElement, internalLinkControlSetting);
      return;
    }

    if (isFromBrowsingHistory) {
      const booksElement = [...categoryElement.children].find((el) => el.matches(".books"));
      toggleCategories(categoryElement);
      toggleBooks(booksElement);
      updateHistoryAndAnimate(internalLinkElement, categoryElement, internalLinkControlSetting);
      return;
    }

    const newUrl = categoryElement.querySelector(".category_spine_link").getAttribute("href");
    getUrlData(categoryElement, newUrl, internalLinkControlSetting);
  };

  // イベントを登録する場合
  if (registerEvent) {
    internalLinkElement.addEventListener("click", handleClick);
    return;
  }

  // イベント登録しない場合、ハンドラ関数を返す
  return handleClick;
}

/**
 * 内部リンクのクリックイベントを設定します
 * @param {HTMLElement} categoryInfoElement - カテゴリ情報要素
 * @param {HTMLElement} booksElement - 本の要素
 */
function setClickEventInternalLinks(categoryInfoElement, booksElement) {
  // カテゴリ詳細の内部リンクを設定する
  categoryInfoElement.querySelectorAll('.category-detail a[href^="/"]').forEach((el) => setInternalDispatchEvent(el));

  // 本の詳細の内部リンクを設定する
  booksElement.querySelectorAll('.book-detail a[href^="/"]').forEach((el) => setInternalDispatchEvent(el));
}

/**
 * モーダル上の内部リンクのクリックイベントを設定します
 */
function setClickEventModalButtonLinks() {
  // モーダルの内部リンクを設定する
  const modalWrapper = document.getElementById("modal-wrapper");
  modalWrapper?.querySelectorAll('.entrance a[href^="/"]').forEach((el) => setInternalDispatchEvent(el));
}

/**
 * カテゴリ（索引）のクリックイベントを設定します
 */
function setClickEventCategory() {
  document.querySelectorAll(".category").forEach(function (element) {
    const eleLink = element.querySelector(".category_spine_link");
    const newUrl = eleLink.getAttribute("href");

    eleLink.addEventListener("click", function (event) {
      event.preventDefault(); // デフォルトのクリック動作をキャンセル

      if (!isClickable) return; // アニメーション中はクリックを無効化（200ms）

      const isOpen = util.isOpenCategory(element);
      if (isOpen) {
        // 子カテゴリをすべて閉じる
        const categoryChildElements = [...element.querySelectorAll(".category")].reverse();
        categoryChildElements.forEach((categoryChildElement) => {
          if (!util.isOpenCategory(categoryChildElement)) return;
          const booksElement = [...categoryChildElement.children].find((el) => el.matches(".books"));
          toggleCategories(categoryChildElement);
          toggleBooks(booksElement);
        });
        // フロアマップの同期処理
        floorMap.closeAllChildren(element.id);
      } else {
        // フロアマップの同期処理
        floorMap.openToNode(element.id);
      }

      // デバイスの向きに応じたカテゴリ制御設定を取得
      const categoryControlSetting = util.isLandscape() ? siteSettings.dataset.categoryControlLandscape : siteSettings.dataset.categoryControlPortrait;

      // URLからカテゴリデータを取得して表示する
      getUrlData(element, newUrl, categoryControlSetting);
    });
  });
}

/**
 * カテゴリ（索引）の左右移動ボタンのクリックイベントを設定します
 */
function setClickEventMoveLeftRightBtn() {
  const moveIdElement = document.querySelector("#move-btn");
  const buttons = moveIdElement.querySelectorAll(".image-btn");

  buttons.forEach((button) => {
    const isLeftArrow = button.firstElementChild.classList.contains("left-arrow");
    const isRightArrow = button.firstElementChild.classList.contains("right-arrow");

    if (isLeftArrow || isRightArrow) {
      button.addEventListener("click", handleArrowClick.bind(null, isLeftArrow));
    }
  });
}

/**
 * 検索アイコンボタンのクリックイベントを設定します
 */
function setClickEventOpenSearchBtn() {
  const searchBtnElement = document.querySelector("#open-search");
  setInternalDispatchEvent(searchBtnElement);
}

/**
 * 左右矢印クリックイベントのハンドラーです
 * @param {boolean} isLeftArrow - 左矢印かどうかのフラグ
 * @param {Event} event - クリックイベント
 */
function handleArrowClick(isLeftArrow, event) {
  event.preventDefault(); // デフォルトのクリック動作をキャンセル

  const sideControlSetting = util.isLandscape() ? siteSettings.dataset.sideControlLandscape : siteSettings.dataset.sideControlPortrait;

  const position = sideControlSetting == gon.alignment.OPEN_CENTERING ? constants.ELEMENT_POSITION.CENTER : constants.ELEMENT_POSITION.LEFT;

  let referencePointElement = util.getElementAtPosition(position);
  if (!referencePointElement) return;

  const isReferencePointCategoryInfo = referencePointElement.classList.contains("category-info");
  const isReferencePointBooks = referencePointElement.classList.contains("books");

  let targetElement;

  if (isLeftArrow) {
    targetElement = handleLeftArrow(referencePointElement, position);
  } else {
    targetElement = handleRightArrow(referencePointElement, isReferencePointCategoryInfo, isReferencePointBooks);
  }

  if (!util.isCategory(targetElement)) return;

  // フロアマップ内の対象を開く
  floorMap.openToNode(targetElement.id);

  // カテゴリが閉じている場合は開く
  if (sideControlSetting == gon.alignment.OPEN_LEFT_ALIGNMENT || sideControlSetting == gon.alignment.OPEN_CENTERING) {
    if (!util.isOpenCategory(targetElement)) {
      const linkElement = targetElement.querySelector(".category_spine_link");
      const newUrl = linkElement.getAttribute("href");
      getUrlData(targetElement, newUrl, sideControlSetting);
      return;
    }
  }

  targetElement = targetElement.querySelector(".category_spine_link");
  moveAnimation(constants.MULTI_LAYER.CATEGORY, targetElement, sideControlSetting);
  updateHistoryElement(targetElement);
}

/**
 * 左矢印クリック時の処理を行います
 * @param {Element} referencePointElement - 参照ポイントとなる要素
 * @param {string} position - 位置の指定 ('CENTER' または 'LEFT')
 * @returns {Element} - 移動先の要素
 */
function handleLeftArrow(referencePointElement, position) {
  // 規定位置が左側であり、参照要素がカテゴリであり、範囲内にある場合
  if (position === constants.ELEMENT_POSITION.LEFT && util.isCategory(referencePointElement) && util.isElementWithinBounds(referencePointElement, position)) {
    return referencePointElement;
  }

  // 左隣の要素を初期ターゲットとして設定
  let targetElement = referencePointElement.previousElementSibling;

  // ターゲットがカテゴリの場合、子カテゴリを探索
  if (util.isCategory(targetElement)) {
    // 子カテゴリを逆順で探索し、表示されているものを探す
    while (targetElement) {
      const viewChildCategory = findVisibleChildCategory(targetElement, true);
      if (!viewChildCategory) break;
      targetElement = viewChildCategory;
    }
  } else {
    // ターゲットがカテゴリでない場合は親要素をターゲットに設定
    targetElement = referencePointElement.parentElement;
  }

  return targetElement;
}

/**
 * 右矢印クリック時の処理を行います
 * @param {Element} referencePointElement - 参照ポイントとなる要素
 * @param {boolean} isReferencePointCategoryInfo - 参照ポイントがカテゴリ詳細かどうか
 * @param {boolean} isReferencePointBooks - 参照ポイントが背表紙かどうか
 * @returns {Element} - 移動先の要素
 */
function handleRightArrow(referencePointElement, isReferencePointCategoryInfo, isReferencePointBooks) {
  let targetElement;

  if (isReferencePointBooks) {
    targetElement = findNextVisibleCategorySibling(referencePointElement);
  } else {
    targetElement = isReferencePointCategoryInfo ? referencePointElement.parentElement.nextElementSibling : referencePointElement.nextElementSibling;

    const viewChildCategory = findVisibleChildCategory(isReferencePointCategoryInfo ? referencePointElement.parentElement : referencePointElement);
    if (viewChildCategory) targetElement = viewChildCategory;

    if (!util.isCategory(targetElement) && !viewChildCategory) {
      targetElement = findNextVisibleCategorySibling(referencePointElement);
    }
  }

  return targetElement;
}

/**
 * 子カテゴリを探索して表示されている要素を取得します
 * @param {Element} categoryElement - カテゴリ要素
 * @param {boolean} shouldReverse - 要素を逆順で探索するか
 * @returns {Element} - 表示されている子カテゴリ要素
 */
function findVisibleChildCategory(categoryElement, shouldReverse = false) {
  const childrenArray = shouldReverse ? [...categoryElement.children].reverse() : [...categoryElement.children];
  return childrenArray.find((child) => util.isViewCategory(child));
}

/**
 * 親要素の次のカテゴリを再帰的に探索し、表示されている要素を取得します
 * @param {Element} element - 検索対象要素
 * @returns {Element} - 表示されている親カテゴリの次の要素
 */
function findNextVisibleCategorySibling(element) {
  let parentNextElement = element.parentElement.nextElementSibling;

  while (parentNextElement) {
    if (util.isViewCategory(parentNextElement)) return parentNextElement;
    if (!parentNextElement.classList.contains("books")) return null;
    parentNextElement = parentNextElement.parentElement.nextElementSibling;
  }

  return null;
}

/**
 * 指定された要素の親要素から class="category" を持つ要素をすべて取得します
 *
 * @param {HTMLElement} element - 探索を開始する要素
 * @returns {HTMLElement[]} 親要素の配列
 */
function findAncestorCategories(element) {
  const parentCategories = [];
  let parent = element.parentElement; // 最初の要素の親を取得

  // 親要素で class="category" を持つものを探す
  while (parent) {
    if (util.isCategory(parent)) {
      parentCategories.push(parent);
    }
    parent = parent.parentElement; // さらに上の親要素を探索
  }

  // 配列を逆順にする
  return parentCategories.reverse();
}

/**
 * カテゴリクリック時にデータを取得して表示します
 * @param {*} eleCategory - .category の要素
 * @param {*} newUrl - .category_spine_link 要素の href 属性の値
 * @param {*} alignment - 移動アニメーションの寄せ位置
 * @param {*} isHistoryUpdate - パンくずリスト（履歴）更新するか
 */
function getUrlData(eleCategory, newUrl, alignment, isHistoryUpdate = true) {
  // 要素の取得
  const eleSpineImage = eleCategory.querySelector(".spine-image");
  const eleCategoryInfo = eleCategory.querySelector(".category-info");
  const eleBooks = [...eleCategory.children].find((el) => el.matches(".books"));

  // 履歴を更新してURLを変更する
  // === issue#392の暫定対応 ここから ===
  // urlにパラメータpを追加
  const p = eleCategory.querySelector('.category_spine_link').dataset.p
  newUrl += p ? "?p=" + p : "";
  // === issue#392の暫定対応 ここまで ===
  history.replaceState(null, "", newUrl);

  // カテゴリの詳細情報と本がすでに読み込まれているかチェック
  if (!eleCategory.classList.contains("data-loaded")) {
    // ローダーを表示して読み込み開始
    const loader = document.querySelector("#loader");
    loader.className = "";
    loader.classList.add("loading");

    // Ajaxでデータを取得
    $.ajax({
      type: "GET",
      url: newUrl,
      dataType: "script",
      beforeSend: function () {
        // リクエスト送信前の処理
      },
      success: function () {
        // 成功時の処理：HTMLを挿入し、各種イベントを設定
        eleCategoryInfo.innerHTML = cateInfoHTML;
        eleBooks.innerHTML = booksHTML;

        // データ取得時の初回表示
        toggleCategories(eleCategory);
        toggleBooks(eleBooks);

        // カテゴリ詳細に配置された検索フォームのクリックイベント設定
        setClickEventSearchForm(eleCategory);

        // 本のクリックイベント設定と内部リンク設定
        setClickEventBook(eleBooks);
        setClickEventInternalLinks(eleCategoryInfo, eleBooks);

        // 画面のリサイズ処理
        handleResize();
      },
      error: function (data) {
        // エラー発生時の処理
      },
      complete: function () {
        // ローディング画面を停止
        loader.classList.add("loaded");

        // 移動アニメーションの実行
        if (alignment != gon.alignment.NONE) {
          moveAnimation(constants.MULTI_LAYER.CATEGORY, eleSpineImage, alignment);
        }

        // パンくずリスト更新
        if (isHistoryUpdate) updateHistoryElement(eleSpineImage.parentElement);

        // カテゴリの詳細、本情報を読み込み済みとしてマーク
        eleCategory.classList.add("data-loaded");
      },
    });
  } else {
    // 読み込み済みの場合は表示を切り替えるのみ
    toggleCategories(eleCategory);
    toggleBooks(eleBooks);

    // 画面のリサイズ処理
    handleResize();

    const isOpen = util.isOpenCategory(eleCategory);

    // 移動アニメーションの実行
    if (isOpen && alignment != gon.alignment.NONE) {
      // 開いている場合かつ移動アニメーション設定されている場合
      moveAnimation(constants.MULTI_LAYER.CATEGORY, eleSpineImage, alignment);
    }

    if (isOpen) {
      // パンくずリスト更新
      if (isHistoryUpdate) updateHistoryElement(eleSpineImage.parentElement);
    }
  }
}

/**
 * カテゴリの表示（詳細、子要素含む）を切り替えます
 * （.category の data-display-detail の値が true の場合表示します）
 * @param {HTMLElement} categoryElement - .category の要素
 */
function toggleCategories(categoryElement) {
  const shouldDisplayDetails = categoryElement.getAttribute("data-display-detail") == "true";
  const categoryInfoElement = categoryElement.querySelector(".category-info");
  const detailElement = categoryInfoElement.querySelector(".category-detail");

  // .category クラスを持つ直下の子要素のみをフィルタリング
  const directChildCategories = [...categoryElement.children].filter((child) => util.isCategory(child));

  // 子カテゴリの表示・非表示
  directChildCategories.forEach((childCategoryElement) => {
    const isView = util.isViewCategory(childCategoryElement);
    childCategoryElement.style.display = isView ? "" : "flex";
    if (isView) {
      // カテゴリの lazyloaded クラスを置換（次回表示時のアニメーション対応のため）
      childCategoryElement.querySelectorAll(".lazyloaded").forEach((imgElement) => {
        imgElement.classList.replace("lazyloaded", "lazyload");
      });
    }
  });

  if (shouldDisplayDetails) {
    // カテゴリ詳細が表示される場合
    const isOpen = Boolean(categoryInfoElement.style.maxWidth);

    // カテゴリ詳細の表示/非表示の切り替え
    categoryInfoElement.style.maxWidth = isOpen ? "" : detailElement.getAttribute("data-detail-width") + "px"; // 詳細開閉アニメーション

    // カテゴリの詳細に含まれるメタ情報取得対象のURLからメタ情報を取得
    getMetaInfo(detailElement);
  } else {
    // カテゴリ詳細が非表示の場合
    detailElement.style.display = "none";
  }
}

/**
 * カテゴリに登録されている本の表示切り替えを実行します
 * @param {HTMLElement} booksElement - .category要素内の.books要素
 */
function toggleBooks(booksElement) {
  const isOpen = Boolean(booksElement.style.maxWidth);

  // 本の詳細をすべて閉じる関数
  function closeAllBookDetails(booksElement) {
    booksElement.querySelectorAll(".book-detail").forEach((detail) => {
      detail.style.display = "none";
    });
    // 本の lazyloaded クラスを置換（次回表示時のアニメーション対応のため）
    booksElement.querySelectorAll(".lazyloaded").forEach((imgElement) => {
      imgElement.classList.replace("lazyloaded", "lazyload");
    });
    booksElement.style.maxWidth = "";
    booksElement.style.display = "none";
  }

  // 本の詳細をすべて開く関数
  function openAllBookDetails(booksElement) {
    let booksWidth = 0;
    // 本の全幅（背表紙のみ）を取得
    booksElement.querySelectorAll(".book").forEach((detail) => {
      booksWidth += detail.offsetWidth;
    });

    booksElement.style.display = "flex";
    if (booksWidth != 0) {
      booksElement.style.maxWidth = booksWidth + "px";
      isClickable = false;
      setTimeout(() => {
        booksElement.style.maxWidth = "none"; // 本の詳細表示の考慮
        isClickable = true;
      }, constants.ANIMATION_DURATION.CATEGORY_OPEN); // アニメーション時間待機
    } else {
      booksElement.style.maxWidth = "none";
    }
  }

  if (isOpen) {
    // 本の詳細をすべて閉じる
    closeAllBookDetails(booksElement);
  } else {
    // 本の詳細を開く
    openAllBookDetails(booksElement);
  }
}

/**
 * 本の背表紙のクリックイベントを設定します
 * （.category の data-display-detail の値が true の場合表示）
 * @param {HTMLElement} booksElement - .category要素内の.books要素
 */
function setClickEventBook(booksElement) {
  //本の詳細表示を切り替える関数
  function toggleBookDetail(booksElement) {
    const bookDetail = booksElement.querySelector(".book-detail");

    if (bookDetail.style.display == "block") {
      bookDetail.style.display = "none";
    } else {
      bookDetail.style.display = "block";
      // クリックした本が中央になるようにスクロール
      moveAnimation(constants.MULTI_LAYER.BOOK, bookDetail);

      // 本の詳細内のurlからメタ情報を取得
      getMetaInfo(bookDetail);
    }
  }

  booksElement.querySelectorAll(".book").forEach((eleBook) => {
    const bookSpine = eleBook.querySelector(".spine-image");
    const showDetail = eleBook.getAttribute("data-display-details");

    if (showDetail == "true") {
      bookSpine.addEventListener("click", () => toggleBookDetail(eleBook));
    }
  });
}

/**
 * 閲覧（パンくず）リストを更新します
 * @param {HTMLElement} targetLinkElement - 履歴更新時の対象要素
 */
function updateHistoryElement(targetLinkElement) {
  let isUpdate = true;
  if (targetLinkElement.classList.contains("history")) isUpdate = false;

  const breadcrumbElement = document.getElementById("breadcrumb");
  const breadcrumbOlElement = breadcrumbElement.firstElementChild;

  // selected クラス削除
  breadcrumbOlElement.childNodes.forEach((liElement) => liElement.classList.remove("selected"));

  if (isUpdate) {
    // 最終閲覧履歴と更新対象の一致判定
    const lastHistory = breadcrumbOlElement.lastElementChild?.firstChild.getAttribute("href");
    if (lastHistory == targetLinkElement.getAttribute("href")) {
      // 最後尾の対象要素に selected クラス追加
      breadcrumbOlElement.lastElementChild.classList.add("selected");
      // パンくずの右寄せ処理（再描画後）
      requestAnimationFrame(() => (breadcrumbOlElement.scrollLeft = breadcrumbOlElement.scrollWidth));
      return;
    }

    // 履歴要素の作成
    const liTagElement = document.createElement("li");
    liTagElement.classList.add("selected");
    const aTagElement = document.createElement("a");
    aTagElement.setAttribute("href", targetLinkElement.getAttribute("href"));
    aTagElement.classList.add("history");
    aTagElement.textContent = targetLinkElement.getAttribute("data-display-name");

    // クリック時の動作登録
    setInternalDispatchEvent(aTagElement);

    // 要素追加
    liTagElement.appendChild(aTagElement);
    breadcrumbOlElement.appendChild(liTagElement);

    // パンくずの右寄せ処理（再描画後）
    requestAnimationFrame(() => (breadcrumbOlElement.scrollLeft = breadcrumbOlElement.scrollWidth));
  } else {
    // 対象要素に selected クラス追加
    breadcrumbOlElement.childNodes.forEach((liElement) => {
      if (liElement == targetLinkElement.closest("li")) liElement.classList.add("selected");
    });
  }
}

/**
 * 子要素がスクロール可能な親要素内に存在するかを検出します
 * @param {HTMLElement} childElement - 子要素
 * @param {HTMLElement} parentElement - 親要素
 * @returns {boolean} - 子要素がスクロール可能な親要素内に存在する場合はtrue、そうでない場合はfalse
 */
function isChildInScrollableParent(childElement, parentElement) {
  let currentElement = childElement;

  // 要素が縦スクロール可能かどうかを判定する関数
  function isHeightScrollable(element) {
    return element.scrollHeight > element.clientHeight;
  }

  // 子要素が親要素の中にある限り、上位の親要素に対してスクロール可能かどうかをチェックする
  while (currentElement && currentElement != parentElement) {
    if (isHeightScrollable(currentElement)) {
      return true;
    }
    currentElement = currentElement.parentElement;
  }

  // 最終的に親要素自体がスクロール可能かどうかをチェックする
  return isHeightScrollable(parentElement);
}

/**
 * 縦スクロールを横スクロールとして処理します
 * @param {WheelEvent} event - スクロールイベント
 */
function handleVerticalScroll(event) {
  // 横スクロールイベントは処理しない
  if (Math.abs(event.deltaY) < Math.abs(event.deltaX)) return;

  // マウスが指している要素が縦スクロール可能なエリアに含まれているか確認
  const mouseHoverElement = document.elementFromPoint(event.clientX, event.clientY);
  if (isChildInScrollableParent(mouseHoverElement, container)) return;

  // 縦スクロールイベントを停止して、移動量を横スクロールに変換
  event.preventDefault();
  container.scrollLeft += event.deltaY;
}

/**
 * ロードアニメーションの完了を監視し、完了した場合に処理を実行します
 */
function handleLoaderAnimationCompletion() {
  // ローダー要素を取得
  const loader = document.getElementById("loader");

  // ローダー要素が存在しない場合は処理しない
  if (!loader) return;

  // アニメーションが完了した時の処理を設定
  loader.addEventListener("animationend", function () {
    // アニメーションが完了した後の処理をここに記述する
    loader.className = "";
  });
}

/**
 * カテゴリクリック時に、カテゴリ情報に記載されたURLからメタタグ情報を取得を取得し、URLを取得した情報へ置き換えます
 * @param {*} element - メタタグ情報取得先のURL
 */
function getMetaInfo(element) {
  // 要素を展開している状態：カテゴリの詳細はmaxWidth != ""、本はdisplay == "block"
  // const isOpen = element.style.maxWidth != "" || element.style.display == "block"
  // if(!isOpen) return;

  element.querySelectorAll('a[data-meta-tag="true"]').forEach((aTagElement) => {
    const url = aTagElement.getAttribute("href");

    // Ajaxでデータを取得
    $.ajax({
      type: "PATCH",
      url: "/categories/get_meta_info",
      dataType: "script",
      data: { url: url },
      beforeSend: function () {
        // リクエスト送信前の処理
      },
      success: function () {
        // 成功時の処理：aタグを取得したメタ情報に置き換える
        aTagElement.outerHTML = meta_info;
      },
      error: function (jqXHR, textStatus, errorThrown) {
        // エラー発生時の処理
        console.error("Error:", textStatus, errorThrown);
        console.log("Response:", jqXHR.responseText); // レスポンスを確認
      },
      complete: function () {},
    });
  });
}

/**
 * カテゴリの詳細に配置された検索フォームのクリックイベントを設定します
 * @param {HTMLElement} categoryElement - 検索に設定された.category要素
 */
function setClickEventSearchForm(categoryElement) {
  const searchForm = categoryElement?.querySelector("#search-form");
  const searchFormSubmit = categoryElement?.querySelector("#search-form-submit");

  if (!searchForm || !searchFormSubmit) return;

  searchFormSubmit.addEventListener("click", function (event) {
    event.preventDefault();

    const formData = new FormData(searchForm);

    const search_word = formData.get("search_word");
    // 検索ワードが空の場合は検索しない
    const errMsg = categoryElement?.querySelector(".search-err-message");
    if (search_word.trim() === "") {
      errMsg.innerHTML = "検索ワードを入力してください";
      return;
    } else {
      errMsg.innerHTML = "";
    }

    const search_target = formData.get("search_target");

    // 索引検索結果をクリア
    categoryElement.querySelectorAll(":scope > .category").forEach((e) => {
      e.remove();
    });

    // 本検索結果をクリア
    const eleBooks = categoryElement.querySelector(".books");
    eleBooks.innerHTML = "";

    // ローダーを表示して読み込み開始
    const loader = document.querySelector("#loader");
    loader.className = "";
    loader.classList.add("loading");

    $.ajax({
      url: "search_result",
      type: "POST",
      data: {
        search_word: search_word,
        search_target: search_target,
      },
      dataType: "script",
      success: function () {
        const categoryInfoEle = categoryElement.querySelector(".category-info");
        categoryInfoEle.insertAdjacentHTML("afterend", catesHTML);
        categoryElement.querySelectorAll(":scope > .category").forEach((e) => {
          e.style.display = "flex";
        });

        eleBooks.innerHTML = booksHTML;

        // 検索結果の索引にクリックイベントを設定
        setClickEventCategoryInSearch(categoryElement);
        // 検索結果の本にクリックイベントを設定
        setClickEventBook(eleBooks);

        handleResize();
      },
      error: function (data) {
        // エラー発生時の処理
        console.log("検索エラー");
      },
      complete: function () {
        // ローディング画面を停止
        loader.classList.add("loaded");
      }
    });
  });
}

/**
 * 検索結果のカテゴリ（索引）のクリックイベントを設定します
 * @param {HTMLElement} searchCategoryElement - 検索に設定された.category要素
 */
function setClickEventCategoryInSearch(searchCategoryElement) {
  searchCategoryElement.querySelectorAll(".category").forEach(function (element) {
    const eleLink = element.querySelector(".category_spine_link");
    const newUrl = eleLink.getAttribute("href");

    eleLink.addEventListener("click", function (event) {
      event.preventDefault(); // デフォルトのクリック動作をキャンセル

      if (!isClickable) return; // アニメーション中はクリックを無効化（200ms）

      const isOpen = util.isOpenCategory(element);
      if (isOpen) {
        // フロアマップの同期処理
        floorMap.closeAllChildren(element.id);
      } else {
        // フロアマップの同期処理
        floorMap.openToNode(element.id);
      }

      // デバイスの向きに応じたカテゴリ制御設定を取得
      const categoryControlSetting = util.isLandscape() ? siteSettings.dataset.categoryControlLandscape : siteSettings.dataset.categoryControlPortrait;

      // URLからカテゴリデータを取得して表示する
      getUrlData(element, newUrl, categoryControlSetting);
    });
  });
}
